<template>
	<div class="w-full flex justify-center">
		<div class="w-full max-w-7xl px-6">
			<div class="flex justify-between items-center h-16">
				<div class="font-semibold text-xl">MP Management</div>
				<div>
					<router-link class="pr-8 hover:underline" active-class="underline" :to="{ name: 'auth-superadmin-login' }">Superadmin</router-link>
					<router-link class="pr-8 hover:underline" active-class="underline" :to="{ name: 'auth-account-manager-login' }">Account manager</router-link>
					<w-language-select />
				</div>
			</div>
			<router-view/>
		</div>
	</div>
</template>
<script>
	import { mapActions, mapState } from 'vuex'
	export default {
		name: 'plain',
		methods: {
			...mapActions([
				'setLang'
			]),
			changeLang (val) {
				this.$i18n.locale = val
				this.setLang(val)
			}
		}
	}
</script>
